function initialize() {
	var myLatlng = new google.maps.LatLng(gcode.lat, gcode.lng);

	var mapOptions = {
		zoom: Number(gcode.zoom),
		center: myLatlng,
        panControl:false,
		zoomControl:false,
		mapTypeControl:false,
		scrollwheel : false,
		draggable: true,
		scaleControl:true,
		streetViewControl:true,
		mapTypeId: 'roadmap'
	};

	var map = new google.maps.Map(document.getElementById(gcode.mapId), mapOptions);
	var styles = [
	{
		"stylers": [
		{ featureType: "water", stylers: [ { hue: "#000"} ] },
		{ featureType: "road", stylers: [ { hue: "#fff" } ] }
		]
	}
	];

		var mapTitle = gcode.title;
		var mapAddress = gcode.address.split(',');
		var mapAdd = gcode.add;
		var styledMap = new google.maps.StyledMapType(styles, {name: "Styled Map"});
		var marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            label: mapAddress[0],
            icon:gcode.marker
         });
         var contentString = '<div id="content">'+
      '<div id="siteNotice">'+
      '</div>'+
      '<h6 id="firstHeading" class="firstHeading">Alpha Missionary Baptist Church</h6>'+
      '<div id="bodyContent">'+
      '<p><a href="https://www.google.com/maps/place/Alpha+Missionary+Baptist+Church/@41.693691,-88.086617,14z/data=!4m5!3m4!1s0x0:0x8bf05155fc0a5041!8m2!3d41.6936915!4d-88.0866166?hl=en-US" target="_blank">'+
      'View Larger Map</a> '+ '</p>'+
      '</div>'+
      '</div>';
        var infowindow = new google.maps.InfoWindow({
    content: contentString
  });
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');
        marker.addListener('click', function() {
            infowindow.open(map,marker);
         });
}
google.maps.event.addDomListener(window, 'load', initialize);
