mainsectionheight = function (container) {
    var headerh = jQuery(".navigation").outerHeight();
    jQuery('.inner-page .banner').css("padding-top", headerh + 'px');

};

equalheight = function(container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel;

        jQuery(container).each(function() {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};

equalheightformobile = function(container) {
    if (jQuery(window).width() > 319) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel;

        jQuery(container).each(function() {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};

jQuery(document).ready(function() {


     //new Menu Responsive
     jQuery(".mobile-icon a").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("open-menu");

    });

    jQuery(document).on('click touchstart', function () {
        jQuery('body').removeClass('open-menu');
    });

     jQuery(".site-header .header-right").on("click touchstart", function (event) {
        event.stopPropagation();
    });

    jQuery(".touch .navigation ul li > a").on("click touchstart",function(event){

       if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
       {
        event.preventDefault();
        event.stopPropagation();
        jQuery(this).parent().addClass("open").siblings().removeClass("open");
       }
      });

    /**/
    jQuery(".home-slider").slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: (jQuery(this).find('.home-slider .item').length > 1) ? true : false,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
         speed: 1000,
    });

 mainsectionheight();
});

jQuery(window).load(function() {
 mainsectionheight();
});

jQuery(window).resize(function() {
 mainsectionheight();
});
